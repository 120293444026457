<template>
<main id="main" class="site-main site-main-detail" v-for="(detail,index) in details" :key="index">
    <div class="postercard">
        <div class="postercard__img">
            <img :src="urlPath+'uploads/media/page/'+detail.image">
        </div>
    </div>
    <section class="blog">
        <div class="container">
            <div class="row">
                <div class="col xs-12 sm-8 md-9">
                    <div class="breadcrumb">
                        <ul>
                            <li><router-link :to="{name:'Home'}">Home</router-link></li>
                            <li><span class="live">{{detail.title}}</span></li>
                        </ul>
                    </div>
                    <h1 class="folio__title">{{detail.title}}</h1>
                    <div class="reviser" v-html="detail.description">

                    </div>
                </div>
                <div class="col xs-12 sm-4 md-3">
                    
                    <div class="related">
                        <h2 class="related__title"><strong>Awesome</strong> Package</h2>
                        <awesome />
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
</template>

<script>
import Awesomepackage from "./Innercomponent/Awesomepackageinner.vue";
import axios from 'axios';
export default {
    name: 'Blog Detail',
    data() {
        return {
            details: undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    mounted() {
        this.getPageinfo(this.$route.params.slug);
    },
    methods: {
        getPageinfo(slugurl) {
            axios.get(this.apiPath+'showpage/about-us')
                .then(response => {
                    // console.log(response.data);
                    this.details = response.data;
                    const descEl = document.querySelector('head meta[name="description"]');
                    const keyEl = document.querySelector('head meta[name="keywords"]');
                    const titleEl = document.querySelector('head title');

                    descEl.setAttribute('content', response.data[0]['seo_description']);
                    keyEl.setAttribute('content', response.data[0]['seo_keyword']);
                    titleEl.textContent = response.data[0]['seo_title'] + " :: Nepal Alternative Treks";
                })
        },
    },
    watch: {
        '$route.params.slug'(newSlug) {
            this.getPageinfo(newSlug);
        }
    },
    components: {
        'awesome': Awesomepackage,
    }
}
</script>
